export const KEY_SCENARIOS_STORAGE = '_clickstream_scenarios_';
export const KEY_LIST_OF_SCENARIOS_STORAGE = '_clickstream_list_of_scenarios_';
export const KEY_SETTINGS_STORAGE = '_clickstream_settings_';
export const KEY_AGENT_STORAGE = '_clickstream_agent_';
export const KEY_UTM_LABELS = '_clickstream_utm_labels_';
export const KEY_EMAIL_EVENTS_STORAGE = '_clickstream_email_events_';
export const KEY_EMAIL_INFO_STORAGE = '_clickstream_email_info_';
export const KEY_SESSION_STORAGE = '_clickstream_session_';
export const KEY_SHOWN_SCENARIOS = '_clickstream_shown_scenarios_';
export const KEY_WIDGET_LAST_CHANGES = '_clickstream_widget_last_changes_';

/**
 * Хранилище для отслеживания переходов из письма рассылки
 */
export const KEY_EMAIL_FORWARDING_STORAGE = '_sberlead_ed_event_';

/**
 * GET-параметр,
 * по которому отслеживается переход на страницу из письма рассылки
 */
export const GET_PARAM_FOR_ED_LETTER_UTM = 'sberlead_letter_utm';

export const KEY_SETTINGS_ENABLED_ON_TABS = 'ENABLED_ON_TABS';
export const KEY_SETTINGS_ENABLED_ON_TABS_TIMEOUT = 'ENABLED_ON_TABS_TIMEOUT_SEC';
