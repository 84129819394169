import { DEFAULT_LOGGING_LEVEL, DEFAULT_MESSAGE_PREFIX } from '~/logging/consts';

export const consoleLogger = (message: any) => {
  console.log(
    '\n\n\nconsoleLogger ===> start\n',
    withMessagesPrefix(message),
    '\n <=== consoleLogger end\n\n\n'
  );
};

export const withMessagesPrefix = (messages: string[] | string) => {
  if (Array.isArray(messages)) {
    if (messages[0] && typeof messages[0] === 'string') {
      messages[0] = DEFAULT_MESSAGE_PREFIX.concat(messages[0]);

      return messages;
    } else {
      messages.unshift(DEFAULT_MESSAGE_PREFIX);

      return messages;
    }
  } else if (typeof messages === 'string') {
    return `${DEFAULT_MESSAGE_PREFIX} ${messages}`;
  } else {
    return [DEFAULT_MESSAGE_PREFIX, messages];
  }
};

function replacer(key: string, value: any) {
  if (key === 'itself' || key === 'context' || value?.constructor?.name === 'StateMachine') {
    return 'Circular object -> name ' + (value?.name || '');
  }

  return value;
}

const convertErrorObjectToString = (obj: any) => {
  let result = '';
  try {
    result = JSON.stringify(obj, replacer);
  } catch (e) {
    console.error('Невозможно конвертировать объект в строку', obj, e);
  }

  return result;
};

export const convertMessageToString = (messages: any[] | string): string => {
  let message: string;
  if (Array.isArray(messages)) {
    const messagesArray = messages.map((msg: any) => {
      if (typeof msg === 'object') {
        return convertErrorObjectToString(msg);
      }

      return msg.toString();
    });

    message = messagesArray.join(' | ');
  } else {
    message = messages;
  }

  return message;
};

export const addMessagesPrefix = (messages: any[] | string) => {
  if (Array.isArray(messages)) {
    const messagesWithPrefix = [...messages];

    if (messagesWithPrefix[0] && typeof messagesWithPrefix[0] === 'string') {
      messagesWithPrefix[0] = DEFAULT_MESSAGE_PREFIX.concat(messages[0]);
    }
    if (messagesWithPrefix.length === 1) {
      return messagesWithPrefix[0];
    }

    return messagesWithPrefix;
  } else {
    return `${DEFAULT_MESSAGE_PREFIX} ${messages}`;
  }
};

export const getLoggingLevel = (loggingLevel: number) => {
  if (!loggingLevel) return DEFAULT_LOGGING_LEVEL;

  return loggingLevel <= 3 && loggingLevel >= -1
    ? loggingLevel
    : DEFAULT_LOGGING_LEVEL;
};
