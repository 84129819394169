import store from 'store2';
import safeParse from '~/helpers/safeParse';
import safeStringify from '~/helpers/safeStringify';

export const getFromLocalStorage = <K = string>(storageName: string, key: string): K => {
  const storage = store(storageName);

  return storage ? storage[key] : null;
};

export const deleteFromLocalStorage = (storageName: string, key: string) => {
  const storage = store(storageName);

  if (storage) {
    delete storage[key];

    store(storageName, storage);
  }
};

export const saveToLocalStorage = (storageName: string, key: string, value: any) => {
  const storage = store(storageName) || {};
  storage[key] = value;
  store(storageName, storage);
};

export const getFromSessionStorage = (storageName: string, key: string): any => {
  const storage = safeParse(window.sessionStorage.getItem(storageName));

  return storage ? storage[key] : null;
};

export const saveToSessionStorage = (storageName: string, key: string, value: any) => {
  const storage = safeParse(window.sessionStorage.getItem(storageName)) || {};
  storage[key] = value;
  window.sessionStorage.setItem(storageName, safeStringify(storage));
};

export const deleteFromSessionStorage = (storageName: string, key: string) => {
  const storage = safeParse(window.sessionStorage.getItem(storageName)) || {};

  if (storage) {
    delete storage[key];
    window.sessionStorage.setItem(storageName, safeStringify(storage));
  }

};

