import { BaseRequest } from '~/api/data/BaseRequest';
import { BaseResponse } from '~/api/data/BaseResponse';
import { RequestDecorator } from '~/api/middlewares/RequestDecorator';

export default class OriginDecorator implements RequestDecorator {
  decorate<T>(
    service: (request: BaseRequest<T>) => Promise<BaseResponse<T>>
  ): (request: BaseRequest<T>) => Promise<BaseResponse<T>> {
    return function (request: BaseRequest<T>) {
      request.origin = location.origin;

      return service(request);
    };
  }
}
