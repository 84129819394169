import RS, { setServerUrlFromScript } from '~/api';
import { setUserData, UserData } from '~/api/UserData';
import { clickstreamLogger } from '~/logging';
import { parseUrlHash } from '~/utils/parseUrlHash';

interface Module {
  init: (isTrackingEvents?: boolean, isTrackingScenarios?: boolean) => void;
  start: () => void;
  stop: () => void;
  isActive: () => boolean;
}

(function () {

  let csaPromise: Promise<Module> = null;

  const init = (
    userData: UserData,
    remoteScriptURL: string,
    isTrackingEvents = true,
    isTrackingScenarios = true
  ) => {
    if (csaPromise) return;

    setServerUrlFromScript(remoteScriptURL);
    if (!setUserData(userData)) return;

    csaPromise = RS.isActive
      .get()
      .then(({ errorData }) => {
        if (errorData.errorFlag) return Promise.reject(errorData);

        return Promise.resolve();
      })
      .then(() => import('./CSA'))
      .then((module) => {
        const { init } = module;
        init(isTrackingEvents, isTrackingScenarios);

        return module;
      })
      .catch((e) => {
        clickstreamLogger.error(`Clickstream деактивирован [${e?.errorType}]`);

        return {
          init: () => {
            clickstreamLogger.warn(
              'Clickstream неактивен: метод init не поддерживается'
            );
          },
          start: () => {
            clickstreamLogger.warn(
              'Clickstream неактивен: метод start не поддерживается'
            );
          },
          stop: () => {
            clickstreamLogger.warn(
              'Clickstream неактивен: метод start не поддерживается'
            );
          },
          isActive: () => {
            clickstreamLogger.warn(
              'Clickstream неактивен: метод start не поддерживается'
            );

            return false;
          },
        };
      });
  };

  const start = () => {
    if (!csaPromise) {
      clickstreamLogger.warn(
        'Clickstream не загружен: метод start не поддерживается'
      );

      return;
    }
    csaPromise.then(({ start }) => start());
  };

  const stop = () => {
    if (!csaPromise) {
      clickstreamLogger.warn(
        'Clickstream не загружен: метод stop не поддерживается'
      );

      return;
    }
    csaPromise.then(({ stop }) => stop());
  };

  const isActive = () => {
    if (!csaPromise) {
      clickstreamLogger.warn(
        'Clickstream не загружен: метод isActive не поддерживается'
      );

      return false;
    }
    let result: boolean;
    (async () => {
      result = await csaPromise.then(({ isActive }) => isActive());
    })();

    return result;
  };

  // Включает демо-режим
  // #type=clickstreamAnalyticUtilitiesDemo&mode=demo
  window.clickstreamAnalyticUtilitiesDemo = {
    isDemo: false,
    hash: '',
  };
  const hash = parseUrlHash();
  if (
    hash?.type &&
    hash?.type === 'clickstreamAnalyticUtilitiesDemo' &&
    hash?.mode &&
    hash?.mode === 'demo'
  ) {
    window.clickstreamAnalyticUtilitiesDemo = {
      isDemo: true,
      hash: window.location.hash,
    };
  }

  if (window.csa && window.csa.q && window.csa.p) {
    const remoteScriptURL = window.csa.p;
    const [
      keyword,
      userData,
      isTrackingEvents,
      isTrackingScenarios,
    ] = window.csa.q[0];

    if (keyword === 'init') {
      init(userData, remoteScriptURL, isTrackingEvents, isTrackingScenarios);
      start();
    }
  }

  window.clickstreamAnalytics = {
    init,
    start,
    stop,
    isActive,
  };
})();
