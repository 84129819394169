import { BaseRequest } from '~/api/data/BaseRequest';
import { BaseResponse } from '~/api/data/BaseResponse';
import { Token } from '~/api/data/Token';
import { RequestDecorator } from '~/api/middlewares/RequestDecorator';

export default class TokenDecorator implements RequestDecorator {
  private tokenPayload: Token;

  constructor() {
    this.tokenPayload = {
      userUID: null,
      sessionId: null,
      analyticsId: null,
    };
  }

  get token() {
    return { ...this.tokenPayload };
  }

  decorate<T>(
    service: (request: BaseRequest<T>) => Promise<BaseResponse<T>>
  ): (request: BaseRequest<T>) => Promise<BaseResponse<T>> {
    return (request: BaseRequest<T>) => {
      request.token = {
        userUID: this.tokenPayload.userUID,
        sessionId: this.tokenPayload.sessionId,
        analyticsId: this.tokenPayload.analyticsId,
      };

      return service(request);
    };
  }

  setParameters(token: Token) {
    this.tokenPayload = {
      ...this.tokenPayload,
      ...token,
    };
  }
}
