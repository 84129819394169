import { clickstreamLogger } from '~/logging';

const safeParse = (string: string) => {
  let result = null;

  try {
    result = JSON.parse(string);
  } catch (e) {
    clickstreamLogger.error('Can`t parse object', string, e);
  }

  return result;
};

export default safeParse;
