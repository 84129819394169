import RS from '~/api';
import { TLogEventPayload } from '~/api/data/LogEventMethod';
import { TLoggerMessage, TLoggingLevel } from '~/logging/types';
import { addMessagesPrefix, consoleLogger, convertMessageToString, getLoggingLevel } from '~/logging/utils';

class ClickstreamLogger {
  /**
   * Logging levels:
   * Main info:  -1
   * Error:       0
   * Warning:     1
   * Info:        2
   * Debug:       3
   */
  loggingLevel: number;

  /**
   * true - если нам нужен дебаг самого логгера.
   * Этот флаг включает уровень дебага 3, а так же логгирует входящие параметры
   * методов логера.
   */
  debugThisLogger: boolean = false;

  constructor(loggingLevel?: TLoggingLevel) {
    this.debugThisLogger && consoleLogger('constructor');
    this.loggingLevel = this.debugThisLogger
      ? 3
      : getLoggingLevel(loggingLevel);
  }


  updateLoggingLevel(loggingLevel: TLoggingLevel) {
    this.loggingLevel = this.debugThisLogger ? 3 : loggingLevel;
    this.debugThisLogger &&
    consoleLogger({
      'method': 'updateLoggingLevel',
      loggingLevel,
      'this.loggingLevel': this.loggingLevel,
    });
    this.debugThisLogger &&
    consoleLogger(
      'this.loggingLevel имеет значение 3, так как включен дебаг класса.'
    );
  }


  sendData(message: TLoggerMessage | string) {

    const payload: TLogEventPayload = {
      messages: [convertMessageToString(message)],
    };

    RS.logEventMethod
      .set(payload)
      .then((e) => {
        if (e.errorData.errorFlag) {
          return Promise.reject(e.errorData.errorText);
        }

        return Promise.resolve();
      })
      .catch((e) => {
        consoleLogger(e);
      });
  }

  error(...message: TLoggerMessage) {
    const prefixedMessages = addMessagesPrefix(message);
    this.debugThisLogger &&
    consoleLogger({ method: 'error', prefixedMessages, message });
    if (
      console &&
      this.loggingLevel >= 0 &&
      typeof console.error === 'function'
    ) {
      console.error(prefixedMessages);
    }

    this.sendData(message);
  }

  warn(...message: TLoggerMessage) {
    const prefixedMessages = addMessagesPrefix(message);
    this.debugThisLogger && consoleLogger({ prefixedMessages, message });

    if (
      console &&
      this.loggingLevel >= 1 &&
      typeof console.warn === 'function'
    ) {
      console.warn(prefixedMessages);
      this.sendData(message);
    }
  }

  info(...message: TLoggerMessage) {
    const prefixedMessages = addMessagesPrefix(message);
    this.debugThisLogger && consoleLogger({ prefixedMessages, message });

    if (
      console &&
      this.loggingLevel >= 2 &&
      typeof console.info === 'function'
    ) {
      console.info(prefixedMessages);
      this.sendData(message);
    }
  }

  debug(...message: TLoggerMessage) {
    const prefixedMessages = addMessagesPrefix(message);
    this.debugThisLogger && consoleLogger({ prefixedMessages, message });

    if (
      console &&
      this.loggingLevel === 3 &&
      typeof console.log === 'function'
    ) {
      console.log(prefixedMessages);
      this.sendData(message);
    }
  }

  trace(...message: TLoggerMessage) {
    const prefixedMessages = addMessagesPrefix(message);
    this.debugThisLogger && consoleLogger({ prefixedMessages, message });

    if (console && typeof console.trace === 'function') {
      console.trace(prefixedMessages);
      this.sendData(message);
    }
  }

  timerBegin(timerLabel: string) {
    const prefixedMessages = addMessagesPrefix(timerLabel);
    this.debugThisLogger &&
    consoleLogger({ prefixedMessages, message: { timerLabel } });

    if (timerLabel && console && typeof console.time === 'function') {
      console.time(timerLabel);
      this.sendData(timerLabel);
    }
  }

  timerEnd(timerLabel: string) {
    const prefixedMessages = addMessagesPrefix(timerLabel);
    this.debugThisLogger &&
    consoleLogger({ prefixedMessages, message: { timerLabel } });
    if (timerLabel && console && typeof console.timeEnd === 'function') {
      console.timeEnd(timerLabel);
      this.sendData(timerLabel);
    }
  }
}

export const clickstreamLogger = new ClickstreamLogger();
