import { BaseRequest } from '~/api/data/BaseRequest';
import { BaseResponse } from '~/api/data/BaseResponse';

const post = <T, RQ = BaseRequest<T>>(url: string, data: RQ, contentType = 'application/json; charset=utf-8') => {
  const request = new Request(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': contentType,
    },
    body: JSON.stringify(data),
  });

  let response: Response;

  return fetch(request)
    .then((resp) => {
      response = resp;
      if (!resp.ok) {
        throw {
          errorData: {
            errorFlag: true,
            errorType: 'ResponseError',
            errorStatus: resp.status,
            errorText: resp.statusText,
          },
        };
      }

      return resp;
    })
    .then(resp => resp.text())
    .then((text: string) => {
      try {
        return JSON.parse(text) as BaseResponse<T>;
      } catch (e) {
        return {
          errorData: {
            errorFlag: true,
            errorType: 'ResponseError',
            errorText: `The request was made and but server responded with incorrect JSON: ${text}`,
          },
        } as BaseResponse<T>;
      }
    })
    .catch((error) => {
      if (response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return {
          errorData: {
            errorFlag: true,
            errorType: 'ResponseError',
            errorStatus: response.status,
            errorText: `The request was made and the server responded with ${response.status} code.`,
          },
        } as BaseResponse<T>;
      } else if (request) {
        // The request was made but no response was received
        return {
          errorData: {
            errorFlag: true,
            errorType: 'RequestError',
            errorStatus: null,
            errorText: 'The request was made but no response was received.',
          },
        } as BaseResponse<T>;
      }
      // Something happened in setting up the request that triggered an Error
      throw error;
    });
};

export default { post };
