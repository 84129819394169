interface IResultObject {
  [index: string]: string;
}

/**
 * Преобразовывает window.location.hash в объект
 * @returns Object
 */
export const parseUrlHash = () => {
  const resultObject: IResultObject = {};

  return window.location.hash
    ? window.location.hash
      .substr(1)
      .split('&')
      .reduce((res: IResultObject, item) => {
        const parts = item.split('=');
        res[parts[0]] = parts[1];

        return res;
      }, resultObject)
    : {};
};
