import { BaseRequest } from '~/api/data/BaseRequest';
import { BaseResponse } from '~/api/data/BaseResponse';
import { Token } from '~/api/data/Token';
import OriginDecorator from '~/api/middlewares/OriginDecorator';
import TokenDecorator from '~/api/middlewares/TokenDecorator';

export class RestBase {

  private tokenDecorator: TokenDecorator = new TokenDecorator();
  private originDecorator: OriginDecorator = new OriginDecorator();

  get token() {
    return this.tokenDecorator.token;
  }

  decorate<T>(service: (request: BaseRequest<T>) => Promise<BaseResponse<T>>) {
    [this.tokenDecorator, this.originDecorator].forEach((middleware) => {
      // tslint:disable-next-line:no-parameter-reassignment
      service = middleware.decorate(service);
    });

    return service;
  }

  setParameters(token: Token) {
    this.tokenDecorator.setParameters(token);
  }

}
