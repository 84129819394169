import RestServices from '~/api';
import { KEY_AGENT_STORAGE } from '~/constants';
import { getFromLocalStorage } from '~/helpers/storage';
import { clickstreamLogger } from '~/logging';

export type UserData = {
  analyticsId: string
};

export const setUserData = (userData: UserData): boolean => {
  if (!userData) {
    clickstreamLogger.error('UserData can`t be empty');

    return false;
  }
  const analyticsId = userData.analyticsId;
  if (typeof analyticsId !== 'string' || analyticsId === '') {
    clickstreamLogger.error('analyticsId can`t be empty');

    return false;
  }
  // Init token params
  RestServices.setParameters({
    userUID: getFromLocalStorage(KEY_AGENT_STORAGE, 'userUID'),
    sessionId: '',
    analyticsId,
  });

  return true;
};