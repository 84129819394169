import Url from 'url-parse';
import { IQuiz, QuizEventProperties } from '~/actions/quiz/types';
import { RestBase } from '~/api/RestBase';
import services, { setServerUrl } from '~/api/Services';
import { AnalyticsEvent, AnalyticsEventList } from '~/api/data/AnalyticsEvent';
import { BaseResponse } from '~/api/data/BaseResponse';
import { DeviceGUID } from '~/api/data/DeviceGuid';
import { FeedbackWidgetData } from '~/api/data/FeedbackWidgetData';
import { TLogEventPayload } from '~/api/data/LogEventMethod';
import { TNewSession } from '~/api/data/NewSession';
import { ProductVersion } from '~/api/data/ProductVersion';
import { PublicKey } from '~/api/data/PublicKey';
import { TScenario, TWidgetLastChanges } from '~/api/data/Scenario';
import { ScenarioEvent, ScenarioEventParameter } from '~/api/data/ScenarioEvent';
import { ServerTime } from '~/api/data/ServerTime';
import { SettingsValue } from '~/api/data/SettingsValue';
import { SitemapInfo } from '~/api/data/SitemapInfo';
import { URL_SERVER } from '~/config';

export const setServerUrlFromScript = (remoteScriptURL: string) => {
  let url;
  if (remoteScriptURL !== null && typeof remoteScriptURL === 'string') {
    __webpack_public_path__ = remoteScriptURL.substr(
      0,
      remoteScriptURL.lastIndexOf('/') + 1
    );
    url = remoteScriptURL;
  } else {
    const scripts = document.getElementsByTagName('script');
    const index = scripts.length - 1;
    const myScript = scripts[index].src;
    __webpack_public_path__ = myScript.substr(0, myScript.lastIndexOf('/') + 1);
    url = myScript;
  }
  const urlObject = new Url(url);
  const serverUrl = urlObject.set('pathname', URL_SERVER, null).toString();
  setServerUrl(serverUrl);
};

export interface RestService<T> {
  get?: (...args: any[]) => Promise<BaseResponse<T>>;
  set?: (...args: any[]) => Promise<BaseResponse<T>>;
}

class RestServices extends RestBase {

  newSession: RestService<TNewSession>;
  serverTime: RestService<ServerTime>;
  productVersion: RestService<ProductVersion>;
  settings: RestService<SettingsValue>;
  widgetLastChanges: RestService<TWidgetLastChanges>;
  scenarios: RestService<TScenario>;
  scenarioEvent: RestService<ScenarioEvent>;
  deviceGUID: RestService<DeviceGUID>;
  publicKey: RestService<PublicKey>;
  siteMap: RestService<SitemapInfo>;
  analyticsEvent: RestService<AnalyticsEventList>;
  createLead: RestService<FeedbackWidgetData>;
  feedList: RestService<Feed>;
  feedCards: RestService<IFeedCardAndCategory>;
  feedEvent: RestService<FeedEventProperties>;
  mailingEvent: RestService<MailingEventProperties>;
  feedSelector: RestService<FeedSelector>;
  isActive: RestService<void>;
  logEventMethod: RestService<TLogEventPayload>;
  quizMethod: RestService<IQuiz>;
  quizEvent: RestService<QuizEventProperties>;

  constructor() {
    super();

    /**
     * NewSession - отправляет информацию о создании новой сессии
     */
    this.newSession = {
      set: () => {
        return this.decorate(services.newSession)({
          action: 'set',
          payload: {
            '@type': 'NewSession',
          },
        });
      },
    };

    /* ServerTime */
    this.serverTime = {
      get: () => services.serverTime({
        action: 'get',
      }),
    };

    /* ProductVersion */
    this.productVersion = {
      get: () => services.productVersion({
        action: 'get',
      }),
    };

    /* Settings */
    this.settings = {
      get: (date: number = Date.now(), code: string = null) => {
        return this.decorate(services.settings)({
          action: 'get',
          requestData: {
            settingsPath: location.pathname,
            settingsDate: date.toString(),
            settingsCode: code,
          },
        }).then((r) => {
          const { errorData } = r;
          if (errorData.errorFlag) {
            return { payload: [], errorData };
          }

          return r;
        });
      },
    };

    /* Widget last changes */
    this.widgetLastChanges = {
      get: () => {
        return this.decorate(services.widgetLastChanges)({
          action: 'get',
        }).then((r) => {
          const { errorData } = r;
          if (errorData.errorFlag) {
            return { payload: [], errorData };
          }

          return r;
        });
      },
    };

    /* Scenarios */
    this.scenarios = {
      get: () => {
        return this.decorate(services.scenarios)({
          action: 'get',
        }).then((r) => {
          const { errorData } = r;
          if (errorData.errorFlag) {
            return { payload: [], errorData };
          }

          return r;
        });
      },
    };

    /**
     *  Param eventParameters is array of objects {parameterCode: parameterValue}
     */

    this.scenarioEvent = {
      set: (eventCode: string,
            eventParameters: { [code: string]: string }[] = [],
            eventTimestamp: number = Date.now()) => {

        const parameters: ScenarioEventParameter[] = [];
        if (eventParameters) {
          // parameterInfo is the object with parameterCode and parameterValue {parameterCode: parameterValue}
          for (const parameterInfo of eventParameters) {
            // Get the parameter code
            const parameterCode = Object.keys(parameterInfo)[0];
            // Get the parameter value
            const parameterValue = parameterInfo[parameterCode];

            parameters.push({
              '@type': 'ScenarioEventParameter',
              parameterCode,
              parameterValue,
            });
          }
        }

        return this.decorate(services.scenarioEvent)({
          action: 'set',
          payload: {
            '@type': 'ScenarioEvent',
            eventCode,
            eventTimestamp,
            'eventParameters': parameters,
          },
        });
      },
    };

    /* DeviceGUID */
    this.deviceGUID = {
      get: () => {
        return this.decorate(services.deviceGuid)({
          action: 'get',
        });
      },
    };

    this.publicKey = {
      get: () => {
        return services.publicKey();
      },
    };

    this.siteMap = {
      get: () => {
        return this.decorate(services.siteMap)({
          action: 'get',
        });
      },
      set: ({ siteMapXMLsArray, sitemapId, siteMapUrl }) => {
        return this.decorate(services.siteMap)({
          action: 'set',
          payload: {
            '@type': 'SitemapInfo',
            'url': siteMapUrl,
            'sitemaps': siteMapXMLsArray,
            sitemapId,
          },
        });
      },
    };

    /**
     *  Param analyticsEventList is array of objects {"eventTime": value, "eventData": "value"}
     */
    this.analyticsEvent = {
      set: (analyticsEventList: AnalyticsEvent[], isEncryptionEnabled: boolean) => {
        return this.decorate(services.analyticsEvent)({
          action: 'set',
          payload: {
            '@type': 'AnalyticsEventList',
            'eventList': analyticsEventList,
            'isEncrypted': isEncryptionEnabled,
          },
        });
      },
    };

    this.createLead = {
      set: (uuid: string, phone: string, email: string, agreementClient: boolean, agreementBank: boolean) => {
        return this.decorate(services.createLead)({
          action: 'set',
          payload: {
            '@type': 'FeedbackWidgetData',
            uuid,
            phone,
            email,
            agreementClient,
            agreementBank,
          },
          requestData: {
            isEncrypted: 'false',
          },
        });
      },
    };

    /**
     * FeedList - Запрос для получения массива лент сайта клиента
     */
    this.feedList = {
      get: (type: Target) => {
        return this.decorate(services.feedList)({
          action: 'get',
          requestData: {
            type,
          },
        }).then((r) => {
          const { errorData } = r;
          if (errorData.errorFlag) {
            return { payload: [], errorData };
          }

          return r;
        });
      },
    };

    /**
     * FeedCards - Запрос для получения массива карточек для ленты рекомендаций
     */
    this.feedCards = {
      get: (requestData: {
        feedId: string;
        someIdList?: string;
        urlLink?: string;
      }) => {
        return this.decorate(services.feedCards)({
          action: 'get',
          requestData,
        }).then((r) => {
          const { errorData } = r;
          if (errorData.errorFlag) {
            return { payload: [], errorData };
          }

          return r;
        });
      },
    };

    /**
     * FeedEvent - Отправка событий лент
     */
    this.feedEvent = {
      set: (feedEventProperties: FeedEventProperties) => {
        return this.decorate(services.feedEvent)({
          action: 'set',
          payload: feedEventProperties,
          origin,
        });
      },
    };

    /**
     * MailingEvent - Отправка EmailEvent.
     */
    this.mailingEvent = {
      set: (mailingProperties: MailingEventProperties) => {
        return this.decorate(services.mailEvent)({
          action: 'set',
          payload: mailingProperties ,
          origin,
        });
      },
    };

    /**
     * FeedSelector - Отправка селектора ленты рекомендаций
     */
    this.feedSelector = {
      set: (selector: string, selectorMobile: string) => {
        return this.decorate(services.feedSelector)({
          action: 'set',
          payload: {
            '@type': 'FeedSelector',
            'uuid': window.clickstreamAnalyticUtilities.EmbeddingFeed.uuid,
            selector,
            selectorMobile,
          },
        });
      },
    };

    /**
     * IsActive - Проверка что организация подключена
     */
    this.isActive = {
      get: () => {
        return this.decorate(services.isActive)({
          action: 'get',
        });
      },
    };

    /**
     * logEventMethod - Логирование ошибок
     */
    this.logEventMethod = {
      set: ({ messages }: TLogEventPayload) => {
        return this.decorate(services.logEventMethod)({
          action: 'set',
          payload: {
            '@type': 'LogEvent',
            messages,
          },
        });
      },
    };

    /**
     * QuizMethod - Запрос для получения массива квизов сайта клиента
     */
    this.quizMethod = {
      get: () => {
        return this.decorate(services.quizMethod)({
          action: 'get',
        }).then((r) => {
          const { errorData } = r;
          if (errorData.errorFlag) {
            return { payload: [], errorData };
          }

          return r;
        });
      },
    };

    /**
     * QuizEvent - Отправка событий квизов
     */
    this.quizEvent = {
      set: (quizEventProperties: QuizEventProperties) => {
        return this.decorate(services.quizEvent)({
          action: 'set',
          payload: quizEventProperties,
          origin,
        });
      },
    };
  }
}

export default new RestServices();
