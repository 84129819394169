import { clickstreamLogger } from '~/logging';

const safeStringify = (object: object): string => {
  let result = '';

  try {
    result = JSON.stringify(object);
  } catch (e) {
    clickstreamLogger.error('Can`t stringify object', object, e);
  }

  return result;
};

export default safeStringify;
