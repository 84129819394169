import { IQuiz, QuizEventProperties } from '~/actions/quiz/types';
import methods from '~/api/Methods';
import { AnalyticsEventList } from '~/api/data/AnalyticsEvent';
import { BaseRequest } from '~/api/data/BaseRequest';
import { DeviceGUID } from '~/api/data/DeviceGuid';
import { FeedbackWidgetData } from '~/api/data/FeedbackWidgetData';
import { TLogEventMethod } from '~/api/data/LogEventMethod';
import { TNewSession } from '~/api/data/NewSession';
import { ProductVersion } from '~/api/data/ProductVersion';
import { PublicKey } from '~/api/data/PublicKey';
import { TScenario, TWidgetLastChanges } from '~/api/data/Scenario';
import { ScenarioEvent } from '~/api/data/ScenarioEvent';
import { ServerTime } from '~/api/data/ServerTime';
import { SettingsValue } from '~/api/data/SettingsValue';
import { SitemapInfo } from '~/api/data/SitemapInfo';
import { URL_SERVER } from '~/config';

let analyticsRestUrl: string = null;
let deviceRestUrl: string = null;
export let origin: string = null;
export let rootUrl: string = null;

export const setServerUrl = (serverUrl: string) => {
  analyticsRestUrl = serverUrl + 'services/';
  deviceRestUrl = serverUrl + 'device/';
  origin = serverUrl.startsWith('http') && new URL(serverUrl).origin;
  rootUrl = serverUrl;
};

setServerUrl(URL_SERVER);

const services = {
  /* clickstream-analytics-bh/services */
  newSession: (data: BaseRequest<TNewSession>) =>
    methods.post<TNewSession>(`${analyticsRestUrl}NewSession`, data),
  settings: (data: BaseRequest<SettingsValue>) =>
    methods.post<SettingsValue>(analyticsRestUrl + 'Settings', data),
  productVersion: (data: BaseRequest<ProductVersion>) =>
    methods.post<ProductVersion>(analyticsRestUrl + 'ProductVersion', data),
  serverTime: (data: BaseRequest<ServerTime>) =>
    methods.post<ServerTime>(analyticsRestUrl + 'ServerTime', data),
  analyticsEvent: (data: BaseRequest<AnalyticsEventList>) =>
    methods.post<AnalyticsEventList>(analyticsRestUrl + 'AnalyticsEvent', data),
  isActive: (data: BaseRequest<void>) =>
    methods.post<void>(analyticsRestUrl + 'IsActive', data),
  logEventMethod: (data: BaseRequest<TLogEventMethod>) =>
    methods.post<TLogEventMethod>(analyticsRestUrl + 'LogEventMethod', data),
  deviceGuid: (data: BaseRequest<DeviceGUID>) =>
    methods.post<DeviceGUID>(analyticsRestUrl + 'DeviceGUID', data),

  /* siteXML services */
  siteMap: (data: BaseRequest<SitemapInfo>) =>
    methods.post<SitemapInfo>(analyticsRestUrl + 'sitemap', data),

  /** Проверка последних изменений виджета */
  widgetLastChanges: (data: BaseRequest<TWidgetLastChanges>) =>
    methods.post<TWidgetLastChanges>(analyticsRestUrl + 'WidgetLastChanges', data),

  /* clickstream-analytics-bh/device */
  publicKey: () => methods.post<PublicKey, {}>(deviceRestUrl + 'key', {}),

  /* Feed services */
  feedList: (data: BaseRequest<Feed>) =>
    methods.post<Feed>(analyticsRestUrl + 'FeedList', data),
  feedCards: (data: BaseRequest<IFeedCardAndCategory>) =>
    methods.post<IFeedCardAndCategory>(analyticsRestUrl + 'recommendation', data),
  feedEvent: (data: BaseRequest<FeedEventProperties>) =>
    methods.post<FeedEventProperties>(
      analyticsRestUrl + 'FeedEvent',
      data
    ),
  mailEvent: (data: BaseRequest<MailingEventProperties>) =>
    methods.post<MailingEventProperties>(
      analyticsRestUrl + 'MailEvent',
      data
    ),
  feedSelector: (data: BaseRequest<FeedSelector>) =>
    methods.post<FeedSelector>(analyticsRestUrl + 'FeedSelector', data),

  /* Scenario services */
  scenarios: (data: BaseRequest<TScenario>) =>
    methods.post<TScenario>(analyticsRestUrl + 'Scenarios', data),
  scenarioEvent: (data: BaseRequest<ScenarioEvent>) =>
    methods.post<ScenarioEvent>(analyticsRestUrl + 'ScenarioEvent', data),
  createLead: (data: BaseRequest<FeedbackWidgetData>) =>
    methods.post<FeedbackWidgetData>(analyticsRestUrl + 'FeedbackEvent', data),

  /* Quiz services */
  quizMethod: (data: BaseRequest<IQuiz>) =>
    methods.post<IQuiz>(analyticsRestUrl + 'QuizMethod', data),
  quizEvent: (data: BaseRequest<QuizEventProperties>) =>
    methods.post<QuizEventProperties>(
      `${analyticsRestUrl}QuizEvent`,
      data
    ),
};

export default services;
